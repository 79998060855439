<template>
  <div>
    <v-btn
      v-if="chequePermissions.edit.includes(user.role)"
      fab
      x-small
      @click="(dialogEditCheque = true), setChequeDate(project_data.cheque.date)"
    >
      <v-icon>edit</v-icon>
    </v-btn>

    <!-- Dialog insert cheque-->
    <DialogBox :model="dialogEditCheque">
      <template slot="toolbar">
        <span>Editar fecha de cheque</span>
      </template>
      <template slot="content">
        <v-form ref="formEditCheque" v-model="formEditChequeValid">
          <DatePicker
            :parent_model="chequeParams.date"
            label="Fecha de cheque"
            :required="true"
            :min="$store.state.user.user.role === 'SUPERADMIN' ? '' : date30DaysBeforeToday()"
            :max="$store.state.user.user.role === 'SUPERADMIN' ? '' : dateNowFormated()"
            @change="setChequeDate"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogEditCheque', 'formEditCheque')">Cerrar</v-btn>
        <v-btn text @click="editCheque()">Editar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import FormatDateMixin from "@/mixins/FormatDateMixin";

export default {
  components: {
    DialogBox,
    DatePicker
  },
  mixins: [FormRulesMixin, FormatDateMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogEditCheque: false,
      formEditChequeValid: false,

      // Cheque permissions
      chequePermissions: {
        edit: ["SUPERADMIN", "ADMIN"]
      },

      chequeParams: {
        project_id: "",
        date: ""
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  methods: {
    async editCheque() {
      if (this.$refs.formEditCheque.validate()) {
        this.chequeParams.project_id = this.project_data.id;
        this.chequeParams.date = this.formatDateToISO8601(this.chequeParams.date);

        await this.$store.dispatch("projects/editCheque", this.chequeParams);
        this.closeDialog("dialogEditCheque", "formEditCheque");
      }
    },

    setChequeDate(value) {
      this.chequeParams.date = value;
    },

    // Close dialog, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.chequeParams = this.$options.data().chequeParams;
      this.chequeImage = null;
    }
  }
};
</script>
