<template>
  <div>
    <v-btn small @click="(dialogInsertCheque = true), (chequeParams.amount = currency(project_data.amount_invested, { fromCents: true }))">
      Insertar cheque
    </v-btn>

    <!-- Dialog insert cheque-->
    <DialogBox :model="dialogInsertCheque">
      <template slot="toolbar">
        <span>Insertar cheque</span>
      </template>
      <template slot="content">
        <v-form ref="formInsertCheque" v-model="formInsertChequeValid">
          <v-text-field v-model.number="chequeParams.amount" type="number" label="Cantidad" disabled />
          <v-text-field v-model="chequeParams.number" label="Número de cheque" :rules="formRules.textRules" />
          <DatePicker
            label="Fecha de cheque"
            :required="true"
            :min="$store.state.user.user.role === 'SUPERADMIN' ? '' : date30DaysBeforeToday()"
            :max="$store.state.user.user.role === 'SUPERADMIN' ? '' : dateNowFormated()"
            @change="setChequeDate"
          />
          <v-file-input
            v-model="chequeImage"
            :accept="['.jpeg', '.png', '.jpg']"
            truncate-length="100"
            :clearable="false"
            prepend-icon="image"
            label="Imagen del cheque"
            :rules="formRules.imageRequiredRules"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogInsertCheque', 'formInsertCheque')">Cerrar</v-btn>
        <v-btn text @click="insertCheque()">Insertar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import CommonMixin from "@/mixins/CommonMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox,
    DatePicker
  },
  mixins: [CommonMixin, FormRulesMixin, FormatDateMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  computed: {
    currency() {
      return currency;
    }
  },
  data() {
    return {
      dialogInsertCheque: false,
      formInsertChequeValid: false,

      chequeParams: {
        project_id: "",
        amount: 0,
        date: "",
        number: ""
      },
      chequeImage: null
    };
  },
  methods: {
    async insertCheque() {
      if (this.$refs.formInsertCheque.validate()) {
        const file = await this.toBase64(this.chequeImage);
        const chequeParamsFormattedData = { ...this.chequeParams };
        chequeParamsFormattedData.project_id = this.project_data.id;
        chequeParamsFormattedData.file = file;
        chequeParamsFormattedData.date = this.formatDateToISO8601(chequeParamsFormattedData.date);
        delete chequeParamsFormattedData.amount;
        console.log(chequeParamsFormattedData);

        await this.$store.dispatch("projects/insertCheque", chequeParamsFormattedData);

        this.closeDialog("dialogInsertCheque", "formInsertCheque");
      }
    },

    setChequeDate(value) {
      this.chequeParams.date = value;
    },

    // Close dialog, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.chequeParams = this.$options.data().chequeParams;
      this.chequeImage = null;
    }
  }
};
</script>
