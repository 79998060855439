<template>
  <div>
    <v-btn small @click="dialogDefendantProject = true"> Pasar a demandado </v-btn>

    <!-- Dialog cancel project -->
    <DialogBox :model="dialogDefendantProject" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de demandar el proyecto?</span>
      </template>
      <template slot="content">
        <v-form ref="formDefendantProject" v-model="formDefendantProjectValid">
          <v-text-field v-model="project_id" label="ID del proyecto" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDefendantProject = false">Cerrar</v-btn>
        <v-btn text :disabled="project_id !== project_data.id" @click="changeToDefendant()">Demandar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogDefendantProject: false,
      formDefendantProjectValid: false,

      project_id: ""
    };
  },
  methods: {
    async changeToDefendant() {
      if (this.$refs.formDefendantProject.validate()) {
        await this.$store.dispatch("projects/changeToDefendant", this.project_id);

        this.dialogDefendantProject = false;
      }
    }
  }
};
</script>
