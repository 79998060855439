<template>
  <div>
    <!-- Data table comments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Comentarios</span>
        <v-spacer />
        <v-btn
          v-if="
            commentPermissions.add.includes(user.role) && (projectEditable.status === 'ACTIVE' || projectEditable.status === 'DEFENDANT')
          "
          rounded
          small
          @click="addCommentDialog = true"
        >
          Añadir comentario
        </v-btn>
      </v-toolbar>
      <DataTable :headers="tableCommentsHeaders" :items="comments" :sortDesc="false" :hideDefaultFooter="false">
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
        <template v-slot:[`item.payment_commitment`]="{ item }">
          <span v-if="item.payment_commitment != null">{{ item.payment_commitment ? "Sí" : "No" }}</span>
        </template>
        <template v-slot:[`item.payment_commitment_date`]="{ item }">
          {{ formatDateL(item.payment_commitment_date) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="commentPermissions.visibility.includes(user.role)"
            icon
            :title="item.visible ? 'Ocultar comentario' : 'Mostrar comentario'"
            @click="$store.dispatch('projects/editProjectComment', { id: item.id, visible: !item.visible })"
          >
            <v-icon v-if="item.visible">visibility</v-icon>
            <v-icon v-else>visibility_off</v-icon>
          </v-btn>
          <v-icon
            v-if="commentPermissions.edit.includes(user.role)"
            @click="(editCommentData.id = item.id), (editCommentData.content = item.content), (editCommentDialog = true)"
          >
            edit
          </v-icon>
          <v-icon
            v-if="commentPermissions.delete.includes(user.role) && !item.consolidated"
            color="red"
            @click="(selectedComment = item), (deleteCommentDialog = true)"
            >delete</v-icon
          >
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog add comment -->
    <DialogBox :model="addCommentDialog">
      <template slot="toolbar">
        <span>Añadir comentario</span>
      </template>
      <template slot="content">
        <v-form ref="formAddComment" v-model="formAddCommentValid">
          <v-select
            v-model="newCommentData.content_type"
            :items="commentTypes"
            attach
            label="Tipo de comentario"
            :rules="formRules.textRules"
          />
          <div v-if="newCommentData.content_type === 'NON-PAYMENT'">
            <v-select v-model="newCommentData.reason" :items="reasonTypes" attach label="Motivo" :rules="formRules.textRules" />
            <v-checkbox
              v-model="newCommentData.payment_commitment"
              :label="newCommentData.payment_commitment ? 'Compromiso de pago: Sí' : 'Compromiso de pago: No'"
            />
            <DatePicker
              v-if="newCommentData.payment_commitment"
              label="Fecha de compromiso de pago"
              :min="dateNextDayFormated()"
              :required="true"
              @change="newCommentData.payment_commitment_date = $event"
            />
          </div>
          <v-text-field v-model="newCommentData.content" label="Contenido" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('addCommentDialog', 'formAddComment')">Cerrar</v-btn>
        <v-btn text @click="addComment()">Añadir</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog edit comment -->
    <DialogBox :model="editCommentDialog">
      <template slot="toolbar">
        <span>Editar comentario</span>
      </template>
      <template slot="content">
        <v-form ref="formEditComment" v-model="formEditCommentValid">
          <v-text-field v-model="editCommentData.content" label="Contenido" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('editCommentDialog', 'formEditComment')">Cerrar</v-btn>
        <v-btn text @click="editComment()">Editar</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog delete comment -->
    <DialogBox :model="deleteCommentDialog" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar este comentario?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="deleteCommentDialog = false">Cerrar</v-btn>
        <v-btn text @click="$store.dispatch('projects/deleteProjectComment', selectedComment.id), (deleteCommentDialog = false)">
          eliminar
        </v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import listeners from "@/firebase/listeners";
import * as currency from "currency.js";

export default {
  components: {
    DataTable,
    DialogBox,
    DatePicker
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  props: {
    projectEditable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Comments datatable
      tableCommentsHeaders: [
        { text: "Usuario", value: "owner_id", sortable: false },
        { text: "Tipo", value: "content_type", sortable: false },
        { text: "Contenido", value: "content", sortable: false },
        { text: "Razón", value: "reason", sortable: false },
        { text: "Compromiso de pago", value: "payment_commitment" },
        { text: "Fecha compromiso de pago", value: "payment_commitment_date" },
        { text: "Fecha", value: "created_at" },
        { text: "", value: "actions" }
      ],

      // Cards actions permissions
      commentPermissions: {
        add: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN", "TECHNICIAN"],
        edit: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN", "TECHNICIAN"],
        visibility: ["SUPERADMIN", "ADMIN"],
        delete: ["SUPERADMIN", "ADMIN"]
      },

      // Form add comment
      formAddCommentValid: false,
      newCommentData: {},
      commentTypes: ["NON-PAYMENT", "TRACKING"],
      reasonTypes: ["HEALTH_WOMAN", "FAMILY_HEALTH", "DEAL", "NOT_PAY", "TRANSFER", "OTHER"],

      // Form edit comment
      formEditCommentValid: false,
      editCommentData: {},

      // Selected comment to delete
      selectedComment: null,

      // Dialogs
      addCommentDialog: false,
      editCommentDialog: false,
      deleteCommentDialog: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      comments: (state) => state.projects.comments
    }),
    currency() {
      return currency;
    }
  },
  async created() {
    await listeners.comments(this.projectEditable.id);
  },
  methods: {
    // Add a comment to the project
    async addComment() {
      if (this.$refs.formAddComment.validate()) {
        this.newCommentData.project_id = this.projectEditable.id;
        if (this.newCommentData.content_type === "NON-PAYMENT" && this.newCommentData.payment_commitment) {
          this.newCommentData.payment_commitment_date = this.formatDateToISO8601(this.newCommentData.payment_commitment_date);
        } else if (this.newCommentData.content_type === "NON-PAYMENT" && !this.newCommentData.payment_commitment) {
          delete this.newCommentData.payment_commitment_date;
        } else {
          delete this.newCommentData.reason;
          delete this.newCommentData.payment_commitment;
          delete this.newCommentData.payment_commitment_date;
        }

        await this.$store.dispatch("projects/createProjectComment", this.newCommentData);
        this.closeDialog("addCommentDialog", "formAddComment");
      }
    },

    // Edit a comment
    async editComment() {
      if (this.$refs.formEditComment.validate()) {
        await this.$store.dispatch("projects/editProjectComment", this.editCommentData);
        this.closeDialog("editCommentDialog", "formEditComment");
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.newCommentData = this.$options.data().newCommentData;
      this.editCommentData = this.$options.data().editCommentData;
    }
  }
};
</script>
