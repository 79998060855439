<template>
  <div>
    <v-btn small @click="dialogRestructure = true">Reestructurar</v-btn>

    <!-- Dialog restructure project -->
    <DialogBox :model="dialogRestructure" :max_width="'700'">
      <template slot="toolbar">
        <span>Reestructurar proyecto</span>
      </template>
      <template slot="content">
        <p>Selecciona el producto financiero que tendrá el proyecto reestructurado:</p>
        <v-row>
          <v-col v-for="(product, i) in $store.state.products.financialProducts" :key="i" cols="12" md="6">
            <div class="text-center mb-3">
              <v-btn x-small :disabled="selectedFinancialProductId === product.id" @click="selectedFinancialProductId = product.id">
                Seleccionar
              </v-btn>
            </div>
            <FinancialProduct :product="product" />
          </v-col>
        </v-row>
      </template>
      <template slot="actions">
        <v-btn text @click="(dialogRestructure = false), (selectedFinancialProductId = '')">Cerrar</v-btn>
        <v-btn text :disabled="!selectedFinancialProductId" @click="restructureProject()">Reestructurar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import DialogBox from "@/components/elements/DialogBox";
import FinancialProduct from "@/components/elements/products/FinancialProduct.vue";

export default {
  components: {
    DialogBox,
    FinancialProduct
  },
  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogRestructure: false,
      selectedFinancialProductId: ""
    };
  },
  async created() {
    await this.$store.dispatch("products/getFinancialProducts");
  },
  methods: {
    async restructureProject() {
      await this.$store.dispatch("projects/restructureProject", {
        project_id: this.projectId,
        financial_product_id: this.selectedFinancialProductId
      });

      this.selectedFinancialProductId = "";
      this.dialogRestructure = false;
    }
  }
};
</script>
