<template>
  <v-dialog v-model="recoveryInfoDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title>Proyectos reestructurados</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="recoveryInfoDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-expansion-panels v-model="panel" multiple class="mt-4">
          <v-expansion-panel v-for="(restructuredProject, i) in info.restructured_projects" :key="i">
            <v-expansion-panel-header class="secondary">Proyecto restructurado {{ i + 1 }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="my-4">
                <v-col cols="12" md="6">
                  <ul>
                    <li><b>Fecha de reestructuración:</b> {{ formatDateL(restructuredProject.date) }}</li>
                    <li><b>Fecha de activación:</b> {{ formatDateL(restructuredProject.active_at) }}</li>
                    <li><b>Cantidad acreditada:</b> {{ currency(restructuredProject.amount_credited, { fromCents: true }) }}</li>
                    <li><b>Cantidad reembolsada:</b> {{ currency(restructuredProject.amount_repaid, { fromCents: true }) }}</li>
                    <li>
                      <b>Cantidad reembolsada restante:</b> {{ currency(restructuredProject.amount_repaid_remaining, { fromCents: true }) }}
                    </li>
                  </ul>
                </v-col>
                <v-col cols="12" md="6">
                  <FinancialProduct :product="restructuredProject.financial_product" />
                </v-col>
              </v-row>
              <CalendarPayments
                v-if="restructuredProject.calendar_payments"
                :calendarPayments="restructuredProject.calendar_payments"
                :financialProductType="restructuredProject.financial_product.type"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import CalendarPayments from "@/components/elements/CalendarPayments";
import FinancialProduct from "@/components/elements/products/FinancialProduct.vue";
import * as currency from "currency.js";

export default {
  components: { CalendarPayments, FinancialProduct },
  mixins: [FormatDateMixin, CommonMixin],
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      recoveryInfoDialog: false,
      panel: []
    };
  },
  computed: {
    currency() {
      return currency;
    }
  },
  methods: {
    openDialog() {
      this.recoveryInfoDialog = true;
    }
  }
};
</script>
