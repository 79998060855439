<template>
  <div>
    <v-card class="px-5 pb-5 secondary" max-width="400" rounded="lg">
      <v-card-title class="d-flex justify-center">
        <p class="mb-0 mr-3">Cheque</p>
        <BtnEditCheque :project_data="project_data" />
      </v-card-title>
      <v-card-text>
        <p class="mb-0"><b>Cantidad:</b> {{ currency(project_data.cheque.amount, { fromCents: true }) }}</p>
        <p class="mb-0"><b>Número:</b> {{ project_data.cheque.number }}</p>
        <p class="mb-0"><b>Insertado por:</b> {{ project_data.cheque.created_by }}</p>
        <p><b>Fecha:</b> {{ formatDateL(project_data.cheque.date) }}</p>
        <div class="text-center">
          <v-btn small rounded @click="getUrlAndOpen(project_data.cheque.photo)">Ver foto</v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Dialog show photo of cheque -->
    <DialogBox :model="dialogShowPhotoCheque" :color="'error'" isdark> </DialogBox>
  </div>
</template>

<script>
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import DialogBox from "@/components/elements/DialogBox";
import BtnEditCheque from "@/components/elements/project/BtnEditCheque";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox,
    BtnEditCheque
  },
  mixins: [FormatDateMixin, CommonMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogShowPhotoCheque: false
    };
  },
  computed: {
    currency() {
      return currency;
    }
  }
};
</script>
