<template>
  <div>
    <v-btn v-if="permissions.changeAmount.includes(user.role)" x-small class="ml-5" @click="handlerBtn()">Cambiar cantidad</v-btn>

    <!-- Dialog change project amount requested -->
    <DialogBox :model="dialogChangeAmountRequested" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de cambiar la cantidad solicitada del proyecto?</span>
      </template>
      <template slot="content">
        <v-form ref="formChangeAmountRequested" v-model="formChangeAmountRequestedValid">
          <v-text-field v-model.number="new_amount" type="number" label="Nueva cantidad solicitada" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogChangeAmountRequested = false">Cerrar</v-btn>
        <v-btn text @click="changeAmountRequested()" :disabled="!amountIsChanged()">Cambiar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import CommonMixin from "@/mixins/CommonMixin";
import DialogBox from "@/components/elements/DialogBox";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin, CommonMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogChangeAmountRequested: false,
      formChangeAmountRequestedValid: false,
      new_amount: 0,

      permissions: {
        changeAmount: ["SUPERADMIN", "ADMIN"]
      }
    };
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    handlerBtn() {
      this.dialogChangeAmountRequested = true;
      this.new_amount = currency(this.project_data.amountRequested, { fromCents: true });
    },

    async changeAmountRequested() {
      if (this.$refs.formChangeAmountRequested.validate()) {
        await this.$store.dispatch("projects/changeProjectAmountRequested", {
          project_id: this.project_data.id,
          amount_requested: this.convertToInt(this.new_amount)
        });

        this.dialogChangeAmountRequested = false;
        this.new_amount = 0;
      }
    },

    amountIsChanged() {
      return this.project_data.amountRequested !== this.convertToInt(this.new_amount);
    }
  }
};
</script>
