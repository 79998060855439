<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :loading="loading"
      :items="items"
      item-value="id"
      item-text="full_name"
      :search-input.sync="search"
      label="Prestataria"
      :readonly="!editable"
      :rules="[(v) => !!v || 'Campo obligatorio']"
      :no-data-text="search !== null && search.length > 2 ? 'No hay datos disponibles' : 'Introduce al menos 3 caracteres para la búsqueda'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selected: null,
      currentBorrower: null
    };
  },
  props: {
    borrowerId: {
      type: String,
      required: false
    },
    listener: {
      type: Object,
      required: false
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  async created() {
    await this.formatField();
  },
  watch: {
    search(val) {
      val && val !== this.selected && this.querySelections(val);
    },
    editable(newVal, oldVal) {
      if (!newVal && oldVal) this.selected = this.currentBorrower;
    },
    selected(val, old) {
      if (val && val !== old) this.$emit("selected", val.id ?? val);
    },
    async listener() {
      await this.formatField();
    }
  },
  methods: {
    async formatField() {
      if (this.borrowerId) {
        this.currentBorrower = await this.$store.dispatch("borrowers/getBorrower", { filter: { id: this.borrowerId } });

        if (this.currentBorrower) {
          this.items.push(this.currentBorrower);
          this.selected = this.currentBorrower.id;
        }
      }
    },
    async querySelections(v) {
      if (v.length > 2) {
        this.loading = true;
        await this.$store.dispatch("borrowers/getBorrowers", { query: v });
        this.items = this.$store.state.borrowers.borrowers;
        if (this.currentBorrower) this.items.push(this.currentBorrower);
        this.loading = false;
      }
    }
  }
};
</script>
