<template>
  <div>
    <v-btn v-if="permissions.changeTechnician.includes(user.role)" x-small class="ml-5" @click="handleBtn()">Cambiar técnico</v-btn>

    <!-- Dialog change project technician -->
    <DialogBox :model="dialogChangeTechnician" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>Cambiar técnico del proyecto</span>
      </template>
      <template slot="content">
        <v-form ref="formChangeTechnician" v-model="formChangeTechnicianValid">
          <v-autocomplete
            v-model="new_tech.technician_id"
            :items="technicians"
            item-value="id"
            item-text="full_name"
            label="Nuevo técnico"
            :rules="[...formRules.textRules, (v) => v !== project_data.technician_id || 'Selecciona un técnico diferente']"
          />
          <v-text-field v-model="new_tech.reason" label="Razón por la que se cambia el técnico" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogChangeTechnician = false">Cerrar</v-btn>
        <v-btn text @click="changeTechnician()" :disabled="!formChangeTechnicianValid">Cambiar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";
import elasticSearchApi from "@/api/elasticSearchApi";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogChangeTechnician: false,
      formChangeTechnicianValid: false,
      technicians: [],
      new_tech: {
        technician_id: "",
        reason: ""
      },

      permissions: {
        changeTechnician: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  methods: {
    async handleBtn() {
      this.new_tech = this.$options.data().new_tech;
      const response = await elasticSearchApi.post("/technician", { filter: { "partner.id": this.project_data.partner_id } });
      this.technicians = response.data.data;
      this.dialogChangeTechnician = true;
    },

    async changeTechnician() {
      if (this.$refs.formChangeTechnician.validate()) {
        await this.$store.dispatch("projects/changeTechnician", {
          project_id: this.project_data.id,
          technician_id: this.new_tech.technician_id,
          reason: this.new_tech.reason
        });

        this.$emit("technicianChanged");
        this.dialogChangeTechnician = false;
      }
    }
  }
};
</script>
