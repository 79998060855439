<template>
  <div>
    <v-card class="pb-10" rounded="lg">
      <v-card-title class="d-flex justify-end">
        <v-btn v-if="editable" :disabled="!permissionsActions.edit.includes(user.role)" icon :to="`/editar-producto/${product.id}`">
          <v-icon small color="black">edit</v-icon>
        </v-btn>
      </v-card-title>
      <div class="text-center">
        <p class="text-title">{{ product.name }}</p>
        <p class="font-italic font-weight-bold mb-0">Créditos necesarios: {{ product.requirements.min_credits }}</p>
        <p class="font-italic font-weight-bold mb-0">Comisión: {{ product.conditions.commission }}</p>
        <p class="font-italic font-weight-bold">Comisión real: {{ product.conditions.commission_real }}</p>
        <p class="primary--text text-h5">{{ product.conditions.monthly_interest }}% interés</p>
        <p v-if="product.expiration_date" class="font-italic">Fecha de expiración: {{ formatDateL(product.expiration_date) }}</p>
        <p v-else class="font-italic">Sin fecha de expiración</p>
        <v-btn class="mt-5" color="primary" @click="dialogShowProduct = true"><v-icon small>add</v-icon> info</v-btn>
      </div>
    </v-card>

    <!-- Dialog show product info -->
    <DialogBox :model="dialogShowProduct" :actionsCenter="true">
      <template slot="toolbar">
        <v-toolbar-title>{{ product.name }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialogShowProduct = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      <template slot="content">
        <p class="mb-1"><b>Tipo:</b> {{ product.type }}</p>
        <p class="mb-1"><b>Número mínimo de créditos:</b> {{ product.requirements.min_credits }}</p>
        <p class="mb-1"><b>Número de meses de carencia:</b> {{ product.conditions.waiting_period }}</p>
        <p class="mb-1"><b>Número de mensualidades:</b> {{ product.conditions.monthly_fees }}</p>
        <p class="mb-1"><b>Intereses mensuales:</b> {{ product.conditions.monthly_interest }}</p>
        <p class="mb-1"><b>Coeficiente de mora:</b> {{ product.conditions.arrear_coeficient }}</p>
        <p class="mb-1"><b>Comisión:</b> {{ product.conditions.commission }}</p>
        <p class="mb-1"><b>Comisión real:</b> {{ product.conditions.commission_real }}</p>
        <p class="mb-1">
          <b>Tags:</b> <v-chip v-for="tag of product.tags" :key="tag" small><v-icon small class="mr-1">label</v-icon>{{ tag }}</v-chip>
          <span v-if="!product.tags">sin tags</span>
        </p>
        <p class="mb-0 text-subtitle-1"><b>Localizaciones:</b></p>
        <ul>
          <li v-for="(office, i) in productOffices" :key="i">
            <p class="mb-0"><b>País:</b> {{ office.country.name }}</p>
            <p class="mb-0"><b>Partner:</b> {{ office.partner.name }}</p>
            <p class="mb-0"><b>Oficina:</b> {{ office.name }}</p>
          </li>
        </ul>
        <p v-if="product.initial_payments" class="mb-0 text-subtitle-1"><b>Pagos iniciales:</b></p>
        <ul v-for="(payment, i) in product.initial_payments" :key="i">
          <li>
            <p class="mb-0">
              <b>{{ payment.name }}:</b>
            </p>
            <ul>
              <li v-for="(value, j) in payment.values" :key="j">
                <p class="mb-0">
                  <b>{{ j }}:</b> {{ value.value }} <span v-if="value.type === 'percentage'">%</span>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </template>
      <template v-if="editable" slot="actions">
        <v-btn v-if="['SUPERADMIN'].includes(user.role)" text x-small @click="dialogToggleProduct = true">
          <span :class="product.disabled ? 'primary--text' : 'red--text'">
            <u>{{ product.disabled ? "Habilitar" : "Deshabilitar" }} producto</u>
          </span>
        </v-btn>
      </template>
    </DialogBox>

    <!-- Dialog confirm edit product -->
    <DialogBox :model="dialogToggleProduct" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>Habilitar / Deshabilitar producto</span>
      </template>
      <template slot="content">
        <span>
          ¿Estás seguro de <b>{{ product.disabled ? "habilitar" : "deshabilitar" }}</b> este producto?
        </span>
      </template>
      <template slot="actions">
        <v-btn text @click="toggleAction()">{{ product.disabled ? "Habilitar" : "Deshabilitar" }}</v-btn>
        <v-btn text @click="dialogToggleProduct = false">Cerrar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  props: {
    product: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      productOffices: [],

      permissionsActions: {
        edit: ["SUPERADMIN"]
      },

      // Show product dialog
      dialogShowProduct: false,

      // Disable/enable product dialog
      dialogToggleProduct: false
    };
  },
  watch: {
    async product() {
      await this.getProductOffices();
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  async created() {
    await this.getProductOffices();
  },
  methods: {
    ...mapActions({
      editFinancialProduct: "products/editFinancialProduct"
    }),

    async getProductOffices() {
      this.productOffices = [];

      for (let i = 0; i < this.product.requirements.offices.length; i++) {
        const officeId = this.product.requirements.offices[i];
        const officeData = await this.$store.dispatch("offices/getOffice", { filter: { id: officeId } });
        this.productOffices.push(officeData);
      }
    },

    // Enable or disabled financial product
    async toggleAction() {
      await this.editFinancialProduct({ id: this.product.id, disabled: !this.product.disabled });

      await this.$store.dispatch("products/getFinancialProducts");

      this.dialogShowProduct = false;
      this.dialogToggleProduct = false;
    }
  }
};
</script>

<style scoped>
.disabledProduct {
  background-color: #aaaaaa;
  opacity: 0.7;
}
</style>
