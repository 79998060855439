<template>
  <div>
    <v-btn small @click="dialogAddInvestment = true">Invertir</v-btn>

    <DialogBox :model="dialogAddInvestment" :max_width="'1000'" :useRenderWatcher="true">
      <template slot="toolbar">
        <span>Añadir una inversión</span>
      </template>
      <template slot="content">
        <v-card class="mb-5">
          <v-toolbar color="black" dense class="white--text" flat>Cantidades del proyecto</v-toolbar>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Moneda</th>
                  <th>Comisión %</th>
                  <th>Restante</th>
                  <th>Solicitado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="newInvest.investor && commissionIsInCurrentVersion()">
                  <td>{{ favoriteCurrency }}</td>
                  <td>{{ investorCommission }}</td>
                  <td>{{ maxAmountToInvestInFavoriteCurrency }}</td>
                  <td>{{ amountRequestedInFavoriteCurrencyWithCommision }}</td>
                </tr>
                <tr>
                  <td>{{ project_data.currency }}</td>
                  <td>{{ newInvest.investor && commissionIsInCurrentVersion() ? investorCommission : "-" }}</td>
                  <td>
                    {{
                      newInvest.investor && commissionIsInCurrentVersion()
                        ? maxAmountToInvestInProjectCurrency
                        : currency(project_data.amount_invested_remaining, { fromCents: true })
                    }}
                  </td>
                  <td>
                    {{
                      newInvest.investor && commissionIsInCurrentVersion()
                        ? amountRequestedInProjectCurrencyWithCommision
                        : currency(project_data.amount_requested, { fromCents: true })
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-form ref="formInvestProject" v-model="formInvestProjectValid">
          <InvestorAutocomplete :editable="true" @selected="handleSelectInvestor($event)" />

          <v-alert
            v-if="
              newInvest.investor &&
              newInvest.investor.type === 'RETAIL' &&
              !newInvest.investor.completed_steps.includes('REGISTER_COMPLETED')
            "
            type="warning"
            color="warning"
          >
            Este inversor no puede invertir hasta que complete su perfil
          </v-alert>
          <div
            v-else-if="
              newInvest.investor && (newInvest.investor.type === 'RETAIL' || (newInvest.investor.type === 'FUND' && partnerExchangeRate))
            "
          >
            <div v-if="commissionIsInCurrentVersion()">
              <p>
                La suma de las carteras en {{ project_data.currency }} es: <b>{{ funds.available_funds ?? 0 }}</b>
              </p>
              <v-text-field
                v-model.number="newInvest.amount"
                type="number"
                label="Cantidad"
                outlined
                :suffix="suffix()"
                class="amount_field mt-1 mb-n5"
                :rules="[
                  (v) => !!v || 'Campo obligatorio',
                  (v) => v > 0 || 'Introduce un valor positivo',
                  insufficientFundsRule,
                  maxAmountToInvestRule
                ]"
              >
                <template slot="append">
                  <v-btn x-small class="ml-2" @click="newInvest.amount = maxAmountToInvestInProjectCurrency.value">max.</v-btn>
                </template>
              </v-text-field>
            </div>
            <div v-else>
              <p class="text-center secondary py-5 mb-0">
                La versión activa de <i>parámetros de inversión</i> no contiene la comisión requerida para el inversor y/o el partner
                seleccionados
                <br />
                <b>Crea una nueva versión con la configuración requerida en "Ajustes Generales"</b>
              </p>
            </div>
          </div>
          <v-alert
            v-else-if="newInvest.investor && newInvest.investor.type === 'FUND' && !partnerExchangeRate"
            type="warning"
            color="warning"
          >
            Este inversor fondo no tiene tipo de cambio asignada para el partner al que pertenece el proyecto
          </v-alert>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('formInvestProject')">Cerrar</v-btn>
        <v-btn text :disabled="!formInvestProjectValid" @click="invest()">invertir</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import DialogBox from "@/components/elements/DialogBox";
import InvestorAutocomplete from "@/components/elements/investor/InvestorAutocomplete";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox,
    InvestorAutocomplete
  },
  mixins: [CommonMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogAddInvestment: false,

      // Form invest project
      formInvestProjectValid: false,
      newInvest: {
        amount: 0,
        investor: null
      },

      // Main wallets of investor selected to invest
      wallets: [],

      // Funds available, withheld, etc...
      funds: {},
      partnerExchangeRate: null
    };
  },
  computed: {
    ...mapState({
      commissions: (state) => state.investments.commissions,
      currencies: (state) => state.currencies.currencies
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    favoriteCurrency() {
      return this.newInvest.investor?.favorite_currency;
    },

    walletAmountInFavoriteCurrency() {
      const available_funds = currency(this.wallets.find((e) => e.currency === this.favoriteCurrency)?.available_funds ?? 0, {
        fromCents: true
      });
      return available_funds;
    },

    walletAmountInProjectCurrency() {
      const available_funds = currency(this.wallets.find((e) => e.currency === this.project_data.currency)?.available_funds ?? 0);
      return available_funds;
    },

    investorCommission() {
      return this.commissions.find((e) => e.key === this.newInvest.investor.commission).value.values[this.project_data.office_id];
    },

    maxAmountToInvestInFavoriteCurrency() {
      const amount_invested_remaining = currency(this.project_data.amount_invested_remaining, { fromCents: true });
      const amount_invested_remaining_with_commission = amount_invested_remaining.multiply(1 + this.investorCommission / 100);
      let amount = 0;

      if (this.newInvest.investor.type === "FUND") {
        amount = currency(amount_invested_remaining_with_commission.value * (1 / this.partnerExchangeRate));
      } else {
        amount = this.convertCurrency(
          currency(amount_invested_remaining_with_commission),
          this.project_data.currency,
          this.favoriteCurrency
        );
      }

      return amount;
    },

    maxAmountToInvestInProjectCurrency() {
      const amountWithCommission = currency(this.project_data.amount_invested_remaining, { fromCents: true }).multiply(
        1 + this.investorCommission / 100
      );
      return amountWithCommission;
    },

    amountRequestedInFavoriteCurrencyWithCommision() {
      const amount_requested = currency(this.project_data.amount_requested, { fromCents: true });
      const amount_requested_with_commission = amount_requested.multiply(1 + this.investorCommission / 100);
      let amount = 0;

      if (this.newInvest.investor.type === "FUND") {
        amount = currency(amount_requested_with_commission.value * (1 / this.partnerExchangeRate));
      } else {
        amount = this.convertCurrency(currency(amount_requested_with_commission), this.project_data.currency, this.favoriteCurrency);
      }

      return amount;
    },

    amountRequestedInProjectCurrencyWithCommision() {
      const amountWithCommission = currency(this.project_data.amount_requested, { fromCents: true }).multiply(
        1 + this.investorCommission / 100
      );
      return amountWithCommission;
    },

    // Control if there are enough funds to invest
    insufficientFundsRule() {
      return (v) => v <= this.funds.available_funds || "Saldo insuficiente";
    },

    // Control max amount available
    maxAmountToInvestRule() {
      return (v) => v <= this.maxAmountToInvestInProjectCurrency || "Máximo de inversión superado";
    }
  },
  async created() {
    await this.$store.dispatch("investments/getInvestmentsSettings");
    await this.$store.dispatch("currencies/getCurrencies");
  },
  methods: {
    // Invest project
    async invest() {
      if (this.$refs.formInvestProject.validate()) {
        await this.$store.dispatch("projects/investProject", {
          project_id: this.project_data.id,
          investor_id: this.newInvest.investor.id,
          amount:
            this.newInvest.amount > this.maxAmountToInvestInProjectCurrency.value
              ? this.maxAmountToInvestInProjectCurrency.intValue
              : this.convertToInt(this.newInvest.amount)
        });
        await this.$store.dispatch("investments/getInvestments", { filter: { "project.id": this.project_data.id } });

        this.closeDialog("formInvestProject");
      }
    },

    // Get the symbol of a currency
    currencySymbol(currency) {
      return this.currencies.find((e) => e.id === currency)?.symbol;
    },

    suffix() {
      let favoriteCurrencyAmount = 0;

      if (this.newInvest.investor.type === "FUND") {
        favoriteCurrencyAmount = currency(this.newInvest.amount * (1 / this.partnerExchangeRate));
      } else {
        favoriteCurrencyAmount = this.convertCurrency(currency(this.newInvest.amount), this.project_data.currency, this.favoriteCurrency);
      }

      return `${this.currencySymbol(this.project_data.currency)} / ${favoriteCurrencyAmount} ${this.currencySymbol(this.favoriteCurrency)}`;
    },

    async getWallets(investorId) {
      const wallets = await this.$store.dispatch("investors/getInvestorWallets", investorId);
      this.wallets = wallets.filter((e) => e.main_wallet === true);
    },

    async getInvestorSelected(investorId) {
      const investor = await this.$store.dispatch("investors/getInvestor", { filter: { id: investorId } });
      this.newInvest.investor = investor;
    },

    // Get the funds of the wallets in the currency of the project
    async getFundsInProjectCurrency() {
      let getTotalFundsData = {};

      if (this.newInvest.investor.type === "RETAIL") {
        getTotalFundsData = { investor_id: this.newInvest.investor.id, currency: this.project_data.currency };
      } else {
        getTotalFundsData = {
          investor_id: this.newInvest.investor.id,
          currency: this.project_data.currency,
          main_wallet_exchange_rate: this.partnerExchangeRate
        };
      }

      const funds = await this.$store.dispatch("investors/getTotalFunds", getTotalFundsData);

      for (const key of this._.keys(funds)) {
        funds[key] = currency(funds[key], { fromCents: true });
      }

      this.funds = funds;
    },

    // Check if investor commission in project partner is set to the current version
    commissionIsInCurrentVersion() {
      if (this.newInvest.investor) {
        if (!this.commissions.some((e) => e.key === this.newInvest.investor.commission)) return false;
        else {
          const commissionObjByInvestor = this.commissions.find((e) => e.key === this.newInvest.investor.commission);
          return this.project_data.office_id in commissionObjByInvestor.value.values;
        }
      }
    },

    // Get the exchange rate of a partner assigned to a fund investor
    getPartnerExchangeRate(partnerId) {
      this.partnerExchangeRate = this.newInvest.investor.exchange_rates[partnerId];
    },

    async handleSelectInvestor(investorId) {
      await this.getInvestorSelected(investorId);
      await this.getWallets(investorId);
      if (this.newInvest.investor.type === "FUND") this.getPartnerExchangeRate(this.project_data.partner_id);
      if (this.newInvest.investor.type === "RETAIL" || (this.newInvest.investor.type === "FUND" && this.partnerExchangeRate)) {
        await this.getFundsInProjectCurrency();
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(formRef) {
      this.$refs[formRef].resetValidation();
      Object.assign(this.$data, this.$options.data());
    }
  }
};
</script>
