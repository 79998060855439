<template>
  <div>
    <v-btn color="red" small @click="dialogCancelProject = true">
      <v-icon color="white" small>clear</v-icon>
    </v-btn>

    <!-- Dialog cancel project -->
    <DialogBox :model="dialogCancelProject" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de cancelar el proyecto?</span>
      </template>
      <template slot="content">
        <v-form ref="formCancelProject" v-model="formCancelProjectValid">
          <v-text-field v-model="cancelProjectParams.project_id" label="ID del proyecto" :rules="formRules.textRules" />
          <v-select v-model="cancelProjectParams.reason" :items="reasons" label="Motivo" :rules="formRules.textRules" />
          <v-textarea v-model="cancelProjectParams.comment" label="Comentario" filled rows="3" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogCancelProject = false">Cerrar</v-btn>
        <v-btn text :disabled="cancelProjectParams.project_id !== project_data.id" @click="cancelProject()">Cancelar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogCancelProject: false,
      formCancelProjectValid: false,

      cancelProjectParams: {
        project_id: "",
        reason: "",
        comment: ""
      },

      reasons: ["REPENTANCE_WOMAN", "HIGH_INTEREST_RATE", "WAIT_TIME", "CONTRACT_DISAGREEMENT", "MICROWD_DECISION", "OTHERS"]
    };
  },
  methods: {
    async cancelProject() {
      if (this.$refs.formCancelProject.validate()) {
        await this.$store.dispatch("projects/cancelProject", this.cancelProjectParams);

        this.dialogCancelProject = false;
      }
    }
  }
};
</script>
