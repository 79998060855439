<template>
  <div>
    <v-btn small @click="dialogReturnProjectToApproved = true">Volver a aprobado</v-btn>

    <DialogBox :model="dialogReturnProjectToApproved" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>Volver a aprobado</span>
      </template>
      <template slot="content">
        <span>¿Estás seguro de cambiar el proyecto nuevamente a estado aprobado?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogReturnProjectToApproved = false">Cerrar</v-btn>
        <v-btn text @click="returnProjectToApproved()">Aceptar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  props: {
    project_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogReturnProjectToApproved: false
    };
  },
  methods: {
    async returnProjectToApproved() {
      await this.$store.dispatch("projects/returnProjectToApproved", this.project_id);

      this.dialogReturnProjectToApproved = false;
    }
  }
};
</script>
