<template>
  <div>
    <v-btn small @click="dialogCondonedProject = true"> Pasar a condonado </v-btn>

    <!-- Dialog cancel project -->
    <DialogBox :model="dialogCondonedProject" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de condonar el proyecto?</span>
      </template>
      <template slot="content">
        <v-form ref="formCondonedProject" v-model="formCondonedProjectValid">
          <v-text-field v-model="condonedProjectParams.project_id" label="ID del proyecto" :rules="formRules.textRules" />
          <v-select v-model="condonedProjectParams.reason" :items="reasons" label="Motivo" :rules="formRules.textRules" />
          <v-textarea v-model="condonedProjectParams.comment" label="Comentario" filled rows="3" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogCondonedProject = false">Cerrar</v-btn>
        <v-btn text :disabled="condonedProjectParams.project_id !== project_data.id" @click="condonedProject()">Condonar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  props: {
    project_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogCondonedProject: false,
      formCondonedProjectValid: false,

      condonedProjectParams: {
        project_id: "",
        reason: "",
        comment: ""
      },

      reasons: ["DEATH_WOMAN", "WOMEN_HEALTH", "TEAM_AGREEMENT", "COLLECTION_UNVIABILITY", "ADVANCE", "OTHERS"]
    };
  },
  methods: {
    async condonedProject() {
      if (this.$refs.formCondonedProject.validate()) {
        await this.$store.dispatch("projects/changeToCondoned", this.condonedProjectParams);

        this.dialogCondonedProject = false;
      }
    }
  }
};
</script>
