<template>
  <div>
    <div class="d-flex justify-space-between px-3 py-1">
      <b class="ma-0">{{ text }}</b>
      <span>{{ value }}</span>
    </div>
    <v-divider v-if="divider" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Object],
      required: true
    },
    divider: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>
