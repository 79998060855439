<template>
  <div>
    <v-btn small @click="dialogPenalize = true">Penalizar</v-btn>

    <!-- Dialog penalize project -->
    <DialogBox :model="dialogPenalize">
      <template slot="toolbar">
        <span>Penalizar proyecto</span>
      </template>
      <template slot="content">
        <v-textarea v-model="comment" class="mx-2" label="Razón" rows="2" filled />
        <v-file-input
          v-model="doc"
          :accept="['.pdf', '.png', '.jpg']"
          truncate-length="100"
          prepend-icon="attach_file"
          label="Documento"
          :rules="[(v) => !v || v.size < 1000000 || 'El documento debe pesar menos de 1MB']"
        />
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog()">Cerrar</v-btn>
        <v-btn text :disabled="!comment" @click="penalizeProject()">Penalizar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import DialogBox from "@/components/elements/DialogBox";
import CommonMixin from "@/mixins/CommonMixin";

export default {
  components: {
    DialogBox
  },
  mixins: [CommonMixin],
  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogPenalize: false,
      comment: "",
      doc: null
    };
  },
  methods: {
    async penalizeProject() {
      const data = {
        project_id: this.projectId,
        comment: this.comment
      };

      if (this.doc) {
        const file = await this.toBase64(this.doc);
        data.file = file;
      }

      await this.$store.dispatch("projects/penalizeProject", data);
      this.closeDialog();
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog() {
      this.comment = "";
      this.doc = null;
      this.dialogPenalize = false;
    }
  }
};
</script>
